/* eslint-disable react/prop-types */
import 'moment-timezone';

import React from 'react';
import PropTypes from 'prop-types';
import ReactMoment from 'react-moment';
import { Box, Typography, Button } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { items } from '../utils';
import { withLayout } from '../components/Layout';
import SEO from '../components/SEO';
import ThumbnailItem from '../components/ThumbnailItem';
import ShareButtons from '../components/ShareButtons';

const Page = ({ pageContext }) => {
  const { playlist } = pageContext;

  // group individual sermons by date
  const sermonDates = playlist
    ? items(playlist.sermons).reduce((arr, sermon) => {
        const dateGroup = arr.findIndex((group) => group[0] === sermon.date);
        if (dateGroup > -1) {
          arr[dateGroup][1].push(sermon);
        } else {
          arr.push([sermon.date, [sermon]]);
        }
        return arr;
      }, [])
    : [];

  return (
    <>
      <SEO
        title={playlist.title}
        cardType="summary_large_image"
        description="Organized by River of Life Christian Fellowship in Loveland, Colorado."
      />

      <Box mb={6}>
        <Typography variant="h1">{playlist.title}</Typography>
      </Box>

      <Box mb={playlist.youtubePlaylistId ? 2 : 6}>
        {sermonDates.map(([date, sermons]) => (
          <Box key={date} mb={2}>
            <Box mb={2}>
              <Typography variant="h5">
                <ReactMoment format="MMMM D, YYYY">{date}</ReactMoment>
              </Typography>
            </Box>
            {sermons.map((sermon) => (
              <ThumbnailItem
                key={sermon.id}
                title={sermon.title}
                subtext={
                  sermon.speakers &&
                  sermon.speakers.edges.map(({ node }) => node.name).join(', ')
                }
                timestamp={sermon.date}
                image={`https://i.ytimg.com/vi/${sermon.youtubeId}/hqdefault.jpg`}
                to={`/media/sermons/${sermon.slug}`}
              />
            ))}
          </Box>
        ))}
      </Box>

      {playlist.youtubePlaylistId && (
        <Box mb={6}>
          <Button
            href={`https://www.youtube.com/playlist?list=${playlist.youtubePlaylistId}`}
            color="secondary"
            endIcon={<ChevronRightIcon />}
          >
            Watch on YouTube
          </Button>
        </Box>
      )}

      <ShareButtons title={playlist.title} />
    </>
  );
};

Page.propTypes = {
  pageContext: PropTypes.object.isRequired,
};

export default withLayout(Page, { container: 'md' });
